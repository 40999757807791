@import 'src/style/var';
.score_container{
  width: 100%;
  height: 100%;
  margin: 20px 0;
  display: flex;
  flex-direction: column;

}

.info_for_user{
  width: 90%;
  margin: 20px 0;
}

.user_score{
  width: 100%;
  height: 100%;
  border-right: 2px solid lighten($color_1, 70);;
  padding: 15px;
  position: relative;
}

.reg_score{
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: column;
  //justify-content: center;
  align-items: center;
}
.auto_parts{
  width: 100%;
  min-height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;


  form{
    width: 100%;

    height: 200px;
    .add_auto_container{
      width: 100%;
      height: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      border-bottom: 2px solid #101423;


    }

  }

  input,select{
    width: 150px;
    height: 30px;
    border: none;
    background: none;
    box-shadow: 0px 0px 5px;
    border-radius: 5px;
  }
  input[type=checkbox],input[type=radio]{
    width: 18px;
    height: 18px;
    box-shadow: 0px 0px 0px;
    margin-left: 10px;
    right: -10px;
    position: absolute;
    cursor: pointer;

  }
  .state{
    display: flex;
    justify-content: space-between;
    width: 60%;
    .state_label{
      border: 1px solid #101423;
      position: relative;
      width: auto;
      height: 30px;
      padding: 0px 15px;
      display: flex;
      align-items: center;
      font-size: 18px;
      color: #101423;

    }

    input[type=file]{
      display: none;
    }
    .file{
      width: 30px;
      height: 30px;
      background-image: url("https://elbakyan.am/Server/img/icon/upload.svg");
      cursor: pointer;
      background-repeat: no-repeat;
      background-size: 200%;
      background-position: center;
      transition: .3s;
      border-radius: 50%;
      margin-right: 10px;
      &:hover{
        box-shadow: 0px 0px 5px;
      }
    }
  }

  textarea{
    resize: none;
    padding: 5px;
    width: 100%;
    height: 100px;
    margin-right: 10px;
    display: block;
    transform-origin: top;
    transform: scale(1);
    transition: .5s;
    &::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: lighten(#101423, 30);
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #101423;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
}