@import "var";

* {
  @include default
}


.rs-loader-wrapper {
  z-index: 1000;
}
  .wrapper {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 0px;
    }


  }

  .progress {
    width: 100%;
    position: absolute;
    top: 10px;
    top: 0;
  }

  .container {
    width: 1140px;
    height: 100%;
    margin: 0 auto;
  }

  .row {
    display: flex;
    align-items: center;
  }

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }


