@import 'src/style/var';

.product_list{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: auto;

  .mark_container{

     .mark{
       padding:5px 10px;
       cursor: pointer;
       position: relative;
       font-size: 18px;
       font-weight: bold;

    }
  }
  .model_container{
    display: none;
    .model{
      padding: 5px;
      cursor:pointer;
      position: relative;
      display: flex;
      align-items: center;
      border-bottom: 1px solid darken(#fff,20);
      transition: .3s;
      &:hover{
        background-color: darken(#fff,10);
      }
     h2{
       font-size: 22px;
       font-weight: bold;
       margin-right: 15px;
       color: #101423;
     }
      > span{
        display: inline-block;
        padding: 5px 10px;
        margin: 0px 10px;
        background-color: darken(#fff,20);
        font-size: 14px;
        font-weight: normal;

      }
      button{
        width: 30px;
        height: 30px;
        position: absolute;
        right: 0;
        background-color: #fd2525;
        color: #fff;
        font-size: 24px;
        line-height: 12px;
        transition: .3s;
        &:hover{
          background-color: darken(#fd2525,20);
          transform: rotate(45deg);
          right: 10px;
        }
      }
    }
  }
  .state{
    width: 20px;
    height: 20px;
    font-size: 28px;
    font-weight: normal;
    display: flex;
    justify-content: center;
    line-height: 12px;
    border: 2px solid lighten(#101423,60);
    color: lighten(#101423,60);
    position: absolute;
    left: -20px;
  }


.show{
  display: block;
}
}

.modal_container{
  display: flex;
  flex-direction: column;




  .modal_img{
    width: 100%;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    height: auto;
    margin-bottom: 10px;
    >div{
      width: 19%;

    }
    img{
      max-width: 100%;
    }

  }
}

