@import 'src/style/var';

.my_auto{
  width: 100%;
 height: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  padding-bottom: 50px;
  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: lighten($color_1, 30);
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $color_1;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

}

.my_auto_block{
  margin-top:65px ;
  display: flex;
  width: 95%;

}

.my_auto_image{
  width: 100%;
  padding: 20px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  >img{
    width: 100%;
  }
}

.my_auto_info{
  padding: 20px 20px;
  width: 80%;
  >nav{
    width: 100%;
    >ul{
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
      >li{
        text-align: left;
        font-size: 16px;
        padding: 5px 0;
        >span{
          padding: 0 5px;
        }
      }
    }
  }
}

.my_auto_button{
  margin: 20px 0 0 0;
  text-align: right;
}