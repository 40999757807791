@import "src/style/var";

header {
  width: 100%;
  height: 60px;
  background-color: $color_1;
  position: relative;
  z-index: 10;

  .header_container {

    justify-content: space-between;
    align-items: center;
  }

  .logo {
    height: 100%;
    display: flex;
    align-items: center;

    img {
      width: 95px;
      height: 30px;
    }
  }

  .header__nav {
    height: 100%;
    > ul {
      height: 100%;
      display: flex;
      align-items: center;

      li {
        cursor: pointer;
        position: relative;
        display: flex;
        align-items: center;

        &:nth-child(2)::after,
        &:nth-child(3)::after{
          content: "";
          position: absolute;
          right: 0px;
          top: calc(50% - 3px);
          border: 7px solid transparent;
          border-top: 7px solid $wheit;
          transition: 0.3s;
        }

        &:nth-child(2):hover::after,
        &:nth-child(3):hover::after {
          top: calc(50% - 13px);
          border: 7px solid transparent;
          border-bottom: 7px solid $wheit;
        }

        &:hover > .Hide_Nav {
          transform: scaleY(1);
        }

        a {
          margin: 0px 15px;
          padding: 5px 10px;
          font-family: Arial;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 21px;
          color: $wheit;
          &:hover{
            text-decoration: none;
            outline: none;
          }

        }
      }
    }
  }

  h2 {
    color: $color_1;
    font-size: 16px;
    background-color: $wheit;
    padding: 5px 20px;
  }
}

.color {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 20px;
  top: calc(50% - 12px);
  font-size: 24px;
  cursor: pointer;
  transition: 0.5s;
  color: $wheit;

  &:hover {
    transform: rotate(180deg);
  }
}


.modal-container{

  margin: 0px 10px;
  &:last-child{
    margin: 0;
  }
}




