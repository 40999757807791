@import "src/style/var";

footer{
  width: 100%;
  height: 150px;
  background: $color_1;

  .footer_container{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .map{
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;

  }
  nav{
    color: $wheit;
    text-align: left;
    ul{
      >h4{
        font-size: 16px;
      }
      padding: 0px;
      li{
        display: flex;
        font-size: 12px;
        align-items: center;
        padding: 2px 5px;
        &:hover{
          color: rgb(126, 126, 126);
        }
        >span{
          margin: 0px 5px;
        }
      }
    }


  }
}