
@import 'src/style/var';

.user_container {
  width: 100%;
  height: 100%;
  &::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: $color_1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: lighten($color_1, 50);

  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.user {
  width: 23%;
  height: 100%;
  border-right: 2px solid lighten($color_1, 70);;
  padding: 15px;

  &__img {
    width: 100%;
    padding: 20px 0;
    background-color: lighten($color_1, 85);;


    > img {
      width: 100%;
    }

  }

  &__name {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 5px 0;
    background-color: $color_1;

    > p {
      font-size: $desktop;
      color: $wheit;
    }
  }

  &__nav {
    width: 100%;
    padding: 20px 0;

    &-ul {
      padding: 0;
      width: 100%;
      display: flex;
      flex-direction: column;

      > li {
        display: flex;
        width: 100%;
        margin: 2px 0;
        padding: 5px 10px;
        transition: .3s;
        cursor: pointer;
        position: relative;
        &:hover {
          background: $color_1;
        }

        &:hover > a {
          color: $wheit;
        }
        &:hover > .nav__icon{
          color: $wheit;
        }
        .nav__icon{
          display: flex;
          width: 17px;
          height: 17px;
          margin-right: 10px;
          color: $color_1;
          transition: .3s;
        }
        .count{
          color: $green;
          background-color: lighten($color_1, 70);
          font-size: 12px;
          font-weight: bold;

          position: absolute;
          top: calc(50% - 10px);
          right: 5px;
          padding: 1px 2px;
        }


        > a {
          font-weight: bold;
          color: $color_1;
          transition: .3s;
          font-size: 12px;

          &:hover {
            text-decoration: none;
          }
        }
      }

    }
  }
  &__icon{
    font-size: 16px;
    color: $wheit;
    margin: 0px 10px;
  }
  &__info{
    width: 100%;
    padding: 10px 5px;
    margin: 5px 0px;
    background: $color_1;
    span{
      font-size: 14px;
      font-weight: bold;
      color: white;

    }
    .mail,.phone{
      margin: 5px 0;}


  }
  &__location{
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px 5px;
    background: $color_1;

    span{
      font-size: 14px;
      font-weight: bold;
      color: white;
    }
    .sircle{
      display: flex;
      margin-right: 5px;
    }
    .city{
      display: flex;
    }




  }

}
.auto{
  width: 77%;
  height: 100%;
  background-color: lighten($color_1,85);
  padding: 0px 20px;

  &__header{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;

    .auto__title{
      >h2{
        font-size: 18px;
      }
    }

  }

}
.add__auto-form{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;


    .items{
      width: 90%;
      height: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      label{
        width: 50%;
      }

      >select{
        width: 50%;
        background-color: $wheit;
        border: 1px solid transparent;
        border-radius: 5px;
        box-shadow: 0px 0px 5px 1px #00000055;
        transition: .5s;
        padding:5px;
      }
      >input{
        width: 50%;
        padding:5px;
        border: 1px solid transparent;
        border-radius: 5px;
        box-shadow: 0px 0px 5px 1px #00000055;
        transition: .5s;
        &::placeholder{

          font-size: 12px;
        }
        &:focus{
          border: 1px solid #04536b;
        }
      }
    .user__form-btn{
      position: absolute;
      right: 5%;
      bottom: 0;
    }


  }


}
