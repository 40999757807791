@import "src/style/var";

.Signup,.Setings{
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h2{
    width: 90;
    text-align: center;
    font-size: 18px;
    padding:10px 0;

  }
  > form{
    width: 90%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-end;


    div{
      width: 100%;
      display: flex;
      justify-content: space-between;
      >input{
        width: 45%;

      }

      @media (max-width: 740px){
        &{
          height: 100px;
          flex-direction: column;
          justify-content: space-between;
        }
      }
    }
    label{
      width: 100%;
      position: relative;
      z-index: 1;


      &::before{
        content: '';
        display: block;
        width: 36px;
        height: 36px;
        position: absolute;
        background-repeat: no-repeat;
        background-position: 5px 8px;


      }


    }
    .fullname::before{
      background-image: url(../../img/icon/name.svg);

    }
    .phone::before{
      background-image: url(../../img/icon/phone.svg);

    }
    .email::before{
      background-image: url(../../img/icon/email.svg);

    }
    .password::before{
      background-image: url(../../img/icon/password.svg);

    }
    .location::before{
      background-image: url(../../img/icon/location.svg);

    }

    input{
      width: 100%;
      padding: 10px 35px;
      border: 1px solid transparent;
      border-radius: 5px;
      transition: .5s;
      box-shadow: 0px 0px 5px 1px #00000055;

    }

    input:focus{
      border: 1px solid #04536b;
    }
    .margin{
      width: 10%;
    }
    button{
      padding: 10px 20px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      background-color: #4267B2;
      transition: .3s;

      &:hover{
        background-color: darken($color: #4267B2, $amount: 10);
      }

    }
    select{
      width: 100%;
      border: none;
      border-radius: 5px;
      padding: 10px 35px;
      box-shadow: 0px 0px 5px 1px #00000055;
      background: #ffffff;
    }

  }
}